import React from 'react';
import styles from './Image.module.css';

const Image = ({src}) => (
  <img src={src} alt="ARALAR HYBRIS" className={`block w-full h-full object-cover z-0 ${styles.Image}`} />
)

Image.defaultProps = {};

export default Image;
