import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import styles from './Fullscreen.module.css';
import Modal from '../Modal';

gsap.registerPlugin(ScrollTrigger);

function Fullscreen(props) {

  // console.log(props.showMobile)
  // console.log(props.showDesktop)

  return (
    <div className={`relative ${props.showMobile ? 'block' : 'hidden'} ${props.showDesktop ? 'md:block' : 'md:hidden'} w-full h-full bg-black snap-center ${styles.Fullscreen}`}> 
      {props.children}
    </div>
  )
}

export default Fullscreen;
