import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Fullscreen from './components/Fullscreen/Fullscreen';
import Image from './components/Image/Image';
import Video from './components/Video';

import introImg from './home3.jpg'
import still1 from './still-01.jpg'
import still2 from './still-02.jpg'
import still3 from './still-03.jpg'
import tripticImg from './Hybris_triptic_website.png'
import teaser1 from './Trial_Hybris-teaser-002-01.mp4';
import teaser2 from './Trial_Hybris-teaser-002-02.mp4';
import teaser3 from './Trial_Hybris-teaser-003.mp4';
import logo from './Aralar_logo.png';

import './App.css';
import VideoEmbed from './components/VideoEmbed';

function App() {

  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause"
  })

  // const elRefs = useRef([])
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const page = useRef(null)
  const hybris = useRef()
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();

  const pageScroll = (triggerWindow) => {
    // console.log(triggerWindow)
    // ScrollTrigger.create({
    //   scroller: triggerWindow.current,
    //   trigger: triggerWindow.current,
    //   start: 'top top',
    //   end: 'bottom bottom',
    //   marker: true,
    //   onUpdate: self => {
    //     // console.log(self.scroll(), self.direction)
    //   }
    // }) 
    
    let q = gsap.utils.selector(triggerWindow.current);
    const sections = q('.section')

    sections.forEach((el) => {
      ScrollTrigger.create({
        scroller: triggerWindow.current,
        trigger: el,
        start: 'top bottom',
        // end: 'bottom top',
        // markers: true,
        onEnter: self => {
          console.log(el.id)
          if (el.id === '2') {
            gsap.to(hybris.current, {
              top: windowSize.current[0] >= 768 ? '50px' : '20px',
              duration: .5,
              delay: .125,
              ease: 'sine.out'
            })
          }
          // self.scroll() >= windowSize.current[1] ? true : false
        },
        onEnterBack: self => {
          if (el.id === '1') {
            gsap.to(hybris.current, {
              top: '50%',
              duration: .5,
              delay: .25,
              ease: 'sine.out'
            })
          }
        }
      })
    })
  }

  const videos = [teaser1, teaser2, teaser3]
  const stills = [still1, still2, still3]

  // useLayoutEffect(() => {
  //   pageScroll(page)
  // }, [])

  return (
    <div className="App">
      <div ref={page} className='relative flex flex-wrap justify-center w-full overflow-scroll text-white align-middle h-dvh scroll-container snap-center snap-y snap-mandatory'>

        <Fullscreen showMobile={true} showDesktop={true}>
          <div id='1' className='relative flex flex-wrap items-center justify-center w-full h-full section' ref={section1}>
            <Image src={introImg} className='absolute z-0'/>

            <h1 className='absolute top-1/2 uppercase z-20 text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] lg:text-4xl lg:tracking-[1.5em] lg:translate-x-[1.5rem] xl:text-5xl xl:tracking-[2.5em] xl:translate-x-[2.5rem] font-HorizontalRegular text-white poiner-events-none' ref={hybris}>Hybris</h1>
          </div>
        </Fullscreen>
      
      
        <Fullscreen showMobile={true} showDesktop={true}>
          <div id='2' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Hybris</h1>

            <div className='flex flex-wrap justify-center w-full text-center md:w-3/4'>
              <p className='my-2 text-sm uppercase !leading-extra-tight md:text-lg'>FROM THE SIMPLICITY OF ONE AND ZERO, FUTURE IS BREED.</p>
              <p className='my-2 text-sm uppercase !leading-extra-tight md:text-lg'>NATURAL AND DIGITAL ORGANISM EMBRACE, YET REJECT EACH OTHER IN A NEW PARALLEL WORLD.</p> 
              <p className='my-2 text-sm uppercase !leading-extra-tight md:text-lg'>LEARNING FROM EACH OTHER, WHAT SHAPE CAN HUMAN BODIES TAKE BETWEEN THESE BALANCING ACTS.</p>
            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={false} showDesktop={true}>
          <div id='3' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Teaser</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12'>
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886941927?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 01"
                />
              </div>
              
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886943179?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 02"
                />
              </div>
              
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886949821?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 03"
                />
              </div>
              
              {/* {videos?.map((item, index) => <VideoEmbed key={index} video={item} poster={stills[index]} />)} */}
            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='3' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient' ref={section2} >

            <h1 className='block absolute top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Teaser</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] w-full gap-x-16 pt-12'>
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886941927?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 01"
                />
              </div>
              
            </div>
          </div>
        </Fullscreen>

        
        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='3' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Teaser</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12 w-full'>
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886943179?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 02"
                />
              </div>
              
            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='3' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Teaser</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12 w-full'>
              
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886949821?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Teaser 03"
                />
              </div>
              
            </div>
          </div>
        </Fullscreen>

        
        <Fullscreen showMobile={true} showDesktop={true}>
          <div id='4' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16' ref={section3}>

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Hybris</h1>
            
            <div className='flex flex-wrap justify-center w-full text-center md:w-3/4'>
              <p className='my-2 text-sm text-center !leading-extra-tight md:text-lg'>
                HYBRIS ENABLES MEANINGFUL DISCUSSIONS ON OUR CHERISHED TOPICS, FOSTERING COLLABORATION ACROSS DIVERSE FIELDS. WE JOYFULLY BROUGHT TOGETHER ARTISTS FROM VARIOUS DOMAINS TO EXPLORE THE INTERSECTION OF NATURE AND DIGITALIZATION, AIMING FOR A HARMONIOUS COEXISTENCE.
              </p>
              
              <p className='my-2 text-sm text-center !leading-extra-tight md:text-lg'>
                OUR VISION FOR HYBRIS INVOLVES A DYNAMIC EVOLUTION, TRANSITIONING FROM A CONSTRUCTED ENVIRONMENT TO A NATURAL ONE, AND FROM HUMAN PRESENCE TO A FULLY DIGITAL AVATAR. WE DISCOVER THREE POTENTIAL FUTURE SCENARIOS, OFFERING A PATHWAY TO SURVIVAL WITHIN COEXISTENCE BETWEEN NATURE AND DIGITALISATION.
              </p>
              
              <p className='my-2 text-sm text-center !leading-extra-tight md:text-lg'>
                EACH FILM SHOWCASES DIFFERENT WORLDS, PORTRAYING AN EXPLORATION OF BALANCE AMONG THE HUMAN BODY, THE PRIMAL FORCES OF NATURE, AND THE ONGOING DIGITALIZATION OF OUR WORLD. INSPIRED BY THE ORIGIN OF MOVEMENT - BIRTH, HYBRIS OFFERS A UNIQUE PERSPECTIVE ON THESE INTRICATE CONNECTIONS.
              </p>

              <p className='my-2 text-sm text-center !leading-extra-tight md:text-lg'>
                DIRECTED BY AARON ALEXANDER ARNOLDT & CORENTIN KOPP<br/>
                PERFORMANCE AND CHOREOGRAPHY BY LOUIS STIENS & SHAKED HELLER<br/>
                EDIT BY FLORIAN KLÄGER | MUSIC BY FABIEN ALEA
              </p>
            </div>
          </div>
        </Fullscreen>        


        <Fullscreen showMobile={false} showDesktop={true}>
          <div id='5' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient-big' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Film</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12'>
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886953500?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 01"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS I</p>
              </div>
              
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886964491?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 02"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS II</p>
              </div>
              
              <div className={`relative aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886965756?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 03"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS III</p>
              </div>

              {/* {videos?.map((item, index) => <VideoEmbed key={index} video={item} poster={stills[index]} />)} */}
            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='5' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient-big' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Film</h1>

            <div className='flex w-full justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12'>
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886953500?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 01"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS I</p>
              </div>

            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='5' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient-big' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Film</h1>

            <div className='flex justify-center w-full text-center h-[calc(100%-6rem)] gap-x-16 pt-12'>
              
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886964491?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 02"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS II</p>
              </div>

            </div>
          </div>
        </Fullscreen>
        

        <Fullscreen showMobile={true} showDesktop={false}>
          <div id='5' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section bg-gradient-big' ref={section2} >

            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Film</h1>

            <div className='flex justify-center text-center h-[calc(100%-6rem)] gap-x-16 pt-12 w-full'>
              
              <div className={`relative w-3/4 aspect-[1/2] overflow-hidden`}>  
                <iframe 
                  src="https://player.vimeo.com/video/886965756?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture" 
                  className='absolute inset-0 w-full h-full'
                  title="Hybris Film 03"
                />
                <p className='block absolute w-full bottom-0 uppercase text-center text-[8px] tracking-[1.5em] md:tracking-[1.5em] font-HorizontalRegular text-white'>HYBRIS III</p>
              </div>

            </div>
          </div>
        </Fullscreen>


        <Fullscreen showMobile={true} showDesktop={true}>
          <div id='6' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16' ref={section3}>

            <h1 className='block absolute top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Hybris</h1>

            <div className='self-center w-full text-center md:w-3/4 '>
              <p className='my-2 text-sm !leading-extra-tight md:text-lg'>THE ENHANCEMENT AND VALUE WE PLACE ON THE HUMAN BODY WILL LEAD US TO NEW HORIZONS.</p>
            </div>
          </div>
        </Fullscreen>         

        
        <Fullscreen showMobile={true} showDesktop={true}>
          <div id='7' className='relative flex flex-wrap items-center justify-center w-full h-full px-6 md:px-16 section' ref={section4} >
            
            <h1 className='block absolute  top-6 md:top-12 uppercase text-center text-lg tracking-[1.5em] translate-x-[.5rem] md:text-2xl md:tracking-[1.5em] md:translate-x-[1.5rem] font-HorizontalRegular text-white'>Hybris</h1>
            
            <div className='w-full text-center md:w-3/4'>
              <img src={logo} className='block w-5/6 m-auto mb-8 md:w-1/3 2xl:w-1/3' alt='ARALAR'/>
              <p className='mt-4 mb-20 text-sm !leading-extra-tight'>INTERDISCIPLINARY COLLECTIVE - AN ENGINE FOR COMPLEMENTARY CREATIVE VENTURES</p>
              <p className='mt-4 mb-0 text-sm !leading-extra-tight'>for releases and news subscribe to<br/><a className='' href="mailto:mail@aralar.world">mail@aralar.world</a></p>
            </div>
          </div>
        </Fullscreen>

      </div>
    </div>
  );
}

export default App;
