import React, { useEffect, useRef, useState } from 'react';
import Modal from './Modal';

function Video({video}) {

//   console.log(video)

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='w-full cursor-pointer md:w-1/3'>
        <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen} video={video} >
            <div className="relative w-full h-full bg-transparent" onClick={() => setIsOpen(true)}>
                <div className={`h-full w-full flex justify-center items-center overflow-hidden z-10`}>  
                    {!isOpen && <h1 className='text-4xl'>Play</h1>}
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default Video;
